<template>
  <v-app id="inspire">
    <v-app-bar
      app
      permanent
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      flat
      v-if="
        $router.currentRoute.name != 'login' &&
        $router.currentRoute.name != 'document' &&
        $router.currentRoute.name != 'forbidden' &&
        $router.currentRoute.name != 'shiyoDocument' &&
        $router.currentRoute.name != 'TinyEditor' &&
        $router.currentRoute.name != 'japan_memo' &&
        $router.currentRoute.name != 'shiyoDocumentViewPDF' &&
        $router.currentRoute.name != 'CAD_Request' &&
        $router.currentRoute.name != 'ShiyoViewImage' &&
        $router.currentRoute.name != 'Sekkei Document New Tab' &&
        $router.currentRoute.name != 'Sekkei Document History' &&
        $router.currentRoute.name != 'Shiyo Comparing History' &&
        $router.currentRoute.name != 'Sekkei Kikaku' &&
        $router.currentRoute.name != 'Sekkei Gyoumu History Compare' &&
        $router.currentRoute.name != 'RuleBook History Compare' &&
        $router.currentRoute.name != 'Sekkei Print' &&
        $router.currentRoute.name != 'Kikaku Print' &&
        $router.currentRoute.name != 'sekkei_kikaku' &&
        $router.currentRoute.name != 'sekkei_gyoumu' &&
        $router.currentRoute.name != 'DSSPDFViewer'
      "
    >
      <!-- <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon> -->
      <v-toolbar-title class="fontStyle">
        <img src="/rule.ico" width="35" height="25" />
        <span class="mr-3" style="color: #1565c0">Document Search System</span>
        <!-- {{currentVersion}} -->
        <span class="subtitle-1"
          ><small>
            Ver. {{ currentVersion ? currentVersion : "-" }}
          </small></span
        >
      </v-toolbar-title>
      <v-spacer />
      <!-- <v-btn @click="testPost()">send test post</v-btn> -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon style="color: #1565c0" left @click="backHome()" v-on="on"
            >mdi-home</v-icon
          >
        </template>
        <span>Return to home</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }" v-if="isAdmin()">
          <v-icon style="color: #1565c0" left @click="dashBoard()" v-on="on"
            >mdi-file-search-outline</v-icon
          >
        </template>
        <span>Keyword Logs</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }" v-if="isAdmin()">
          <v-icon style="color: #1565c0" left @click="userLogs()" v-on="on"
            >mdi-account-search-outline</v-icon
          >
        </template>
        <span>User Logs</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon
            style="color: #1565c0"
            left
            class="ml-1"
            @click="goToUserManual()"
            v-on="on"
            >mdi-account-question-outline</v-icon
          >
        </template>
        <span>User Manual</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon
            style="color: #1565c0"
            left
            class="ml-1"
            @click="systemLogs()"
            v-on="on"
            v-if="$store.state.token.length != 0"
            >mdi-application-cog</v-icon
          >
        </template>
        <span>System Logs</span>
      </v-tooltip>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <v-icon style="color: #1565c0" v-if="$store.state.token.length != 0"
              >mdi-account</v-icon
            >
            <!-- NOTE: added font 2024-06-05 -->
            <span style="font-family: 'MS Gothic'; font-size: 15px;">{{ userInfo.name }}</span>
            <!-- <span>深谷定充</span> -->
          </div>
        </template>
        <v-list>
          <v-list-item @click="feedback()">
            <v-list-item-content>
              <v-list-item-title style="cursor: pointer">
                <span style="font-size: 16px; font-weight: bold">
                  <v-icon style="color: #1565c0" slot="activator"
                    >mdi-comment-quote</v-icon
                  >
                  Feedback</span
                >
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- NOTE: add link for requestMonitoringSystem 2024-03-15 -->
          <!-- <v-list-item @click="gotoRequestMonitoring()">
            <v-list-item-content>
              <v-list-item-title style="cursor: pointer">
                <span style="font-size: 16px; font-weight: bold">
                  <v-icon style="color: #1565c0" slot="activator"
                    >mdi-list-box</v-icon
                  >
                  Request Monitoring</span
                >
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->
          <v-list-item @click="synonym2()">
            <v-list-item-content>
              <v-list-item-title style="cursor: pointer">
                <span style="font-size: 16px; font-weight: bold">
                  <v-icon style="color: #1565c0" slot="activator"
                    >mdi-pencil</v-icon
                  >
                  Multiple Master</span
                >
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="isAdmin()">
            <v-list-item-content>
              <v-list-item-title style="cursor: pointer" @click="Users()">
                <span style="font-size: 16px; font-weight: bold"
                  ><v-icon style="color: #1565c0" slot="activator"
                    >mdi-account-cog</v-icon
                  >
                  &nbsp;&nbsp; Manage Users</span
                >
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="isAdmin()">
            <v-list-item-content>
              <v-list-item-title style="cursor: pointer" @click="openDefaultPassDialog">
                <span style="font-size: 16px; font-weight: bold"
                  ><v-icon style="color: #1565c0" slot="activator"
                    >mdi-lock</v-icon
                  >
                  &nbsp;&nbsp; Default Passwords</span
                >
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- 2022-09-30 -->
          <!-- <v-list-item v-if="isAdmin()"> 
            <v-list-item-content>
              <v-list-item-title style="cursor: pointer" @click="goToCADRequest()">
                <span style="font-size: 16px; font-weight: bold"
                  ><v-icon style="color: #1565c0" slot="activator"
                    >mdi-cog</v-icon
                  >
                  &nbsp;&nbsp; Manage CAD依頼資料 </span
                >
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->

          <!-- <v-list-item>
            <v-list-item-content>
              <v-list-item-title style="cursor: pointer" @click="changePassDialog = true">
                <span style="font-size: 16px; font-weight: bold"
                  ><v-icon style="color: #1565c0" slot="activator"
                    >mdi-lock</v-icon
                  >
                  &nbsp;&nbsp; Change Password</span
                >
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->

          <!---2024-04-12--->
          <v-list-item @click="logout"> <!---2024-04-17 change function--->
            <v-list-item-content>
              <v-list-item-title style="cursor: pointer">
                <span style="font-size: 16px; font-weight: bold"
                  ><v-icon style="color: #1565c0" slot="activator"
                    >mdi-logout-variant</v-icon>
                  &nbsp;&nbsp; Logout</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>

    <!-- DIALOGS -->
    <v-dialog v-model="changePassDialog" max-width="350">
      <v-card>
        <v-card-title class="text-h5">
          <v-icon color="primary" class="mr-3">mdi-lock</v-icon> Change Password
        </v-card-title>

        <v-card-text>
          <v-text-field
            label="Old Password"
            v-model="toSend.oldPass"
            type="password"
          ></v-text-field>
          <v-text-field
            label="New Password"
            type="password"
            v-model="toSend.newPass"
          ></v-text-field>
          <v-text-field
            label="Re-type New Password"
            type="password"
            v-model="toSend.retypeNewPass"
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="red darken-1"
            text
            @click="(toSend = {}), (changePassDialog = false)"
          >
            Cancel
          </v-btn>

          <v-btn color="blue darken-1" text @click="savePassword()">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogSystemLogs" fullscreen pesistent>
      <v-card>
        <v-toolbar style="background-color: #0f3057" dense>
          <v-toolbar-title style="color: white"> System Logs </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon
            color="white"
            @click="getCurrentSystemVersion(), (dialogSystemLogs = false)"
            >mdi-close</v-icon
          >
        </v-toolbar>
        <v-card style="overflow-y: auto" :height="screenSize - 50">
          <sLogs></sLogs>
        </v-card>
      </v-card>
    </v-dialog>

    <!-- NOTE: for force changing of default password 2024-03-04-->
    <v-dialog v-model="forceChangePassDialog" persistent width="300px">
      <v-card>
        <v-card-title>Change Password</v-card-title>
        <v-card-text class="mt-3" autocomplete="off">
          <v-text-field
            autocomplete="new-password"
            v-model="changePassObj.newPass"
            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required]"
            outlined
            dense
            label="Enter new Password"
            :type="showPass ? 'text' : 'password'"
            @click:append="showPass = !showPass"
          ></v-text-field>
          <v-text-field
            autocomplete="new-password"
            :disabled="!changePassObj.newPass"
            v-model="changePassObj.reTypePass"
            :append-icon="showReTypePass ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required]"
            outlined
            dense
            label="Re-Enter new Password"
            :type="showReTypePass ? 'text' : 'password'"
            @click:append="showReTypePass = !showReTypePass"
          ></v-text-field>
          <v-btn
            block
            color="primary"
            @click="saveNewPassword()"
            :disabled="changePassObj.newPass!=changePassObj.reTypePass || !changePassObj.newPass || !changePassObj.reTypePass"
          >save password</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- NOTE: default password dialog 2024-03-04 -->
    <v-dialog
      v-model="defaultPassDialog"
      persistent
      width="500px"
    >
      <v-card>
        <v-card-title
          style="background-color: #f6f5f5; font-family: Arial; font-weight: bold;"
        >Default Password
        <v-spacer />
        <v-icon @click="closeDefaultPassDialog()">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-btn
                color="success"
                dense
                small
                class="mt-2"
                @click="openAddDefPassDialog('Add')"
              >
                <v-icon>mdi-plus</v-icon>
                ADD PASSWORD
              </v-btn>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-simple-table dense>
                <thead>
                  <tr>
                    <th class="text-left" style="font-family: Arial;">No</th>
                    <th class="text-left" style="font-family: Arial;">Password</th>
                    <!-- <th class="text-left" style="font-family: Arial;">Registered By</th>
                    <th class="text-left" style="font-family: Arial;">Registered Date</th> -->
                    <!-- <th class="text-left" style="font-family: Arial;">Action</th> -->
                  </tr>
                </thead>
                <tbody v-if="defaultPassList.length!=0">
                  <tr v-for="(item,index) in defaultPassList" :key="index">
                    <td>{{index+1}}</td>
                    <td>{{item.password}}</td>
                    <!-- <td>{{item.RegisterBy}}</td>
                    <td>{{item.RegisteredDate}}</td> -->
                    <!-- <td>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon
                            v-on="on"
                            color="success"
                            @click="openAddDefPassDialog('Edit'),defPassEditIndex=index"
                          >mdi-pencil</v-icon>
                        </template>
                        <span>Edit Default Password</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon
                            v-on="on"
                            color="error"
                            @click="deleteDefPass(index)"
                          >mdi-trash-can</v-icon>
                        </template>
                        <span>Delete Default Password</span>
                      </v-tooltip>
                    </td> -->
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- NOTE: add default password dialog 2024-03-04 -->
    <v-dialog
      v-model="addDefPassDialog"
      persistent
      width="400px"
    >
      <v-card>
        <v-card-title
          style="background-color: #f6f5f5; font-family: Arial; font-weight: bold;"
        >
          {{defaultPassStatus}} Default Password
          <v-spacer />
          <v-icon @click="closeAddDefPassDialog()">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <v-text-field
            class="mt-2"
            v-model="defaultPass"
            outlined
            dense
            label="Enter a password"
          ></v-text-field>
          <v-btn 
            color="success"
            block
            @click="saveDefaultPass()"
          >
            <v-icon>mdi-content-save-check</v-icon>SAVE
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>

  </v-app>
</template>

<script>
// import moment from "moment";
import sLogs from "./views/SystemLogs";
import axios from "axios";
import Swal from "sweetalert2";
import md5 from "md5-hex";
export default {
  name: "App",

  components: {
    sLogs,
  },

  data: () => ({
    dialogSystemLogs: false,
    changePassDialog: false,
    toSend: {},
    currentVersion: "",
    //NOTE: for force change password 2024-03-04
    forceChangePassDialog: false,
    showPass: false,
    showReTypePass: false,
    changePassObj: {},
    rules: {
      required: value => !!value || 'Required.',
      passMatch: () => (`password you entered don't match`),
    },
    //NOTE: for managing default passwords
    defaultPassDialog: false,
    addDefPassDialog: false,
    defaultPass: '',
    defaultPassStatus: 'Add',
    defPassEditIndex: -1,
    defaultPassList: [],
    currentPass: ''

    //
  }),
  async created() {
    let currentUser = await this.getUserDetails(this.userInfo.originalMailAddress)
    this.currentPass = currentUser.Password
    this.getCurrentSystemVersion();

    this.checkExpirationtime(); //2024-04-17

    this.startExpiryTimeChecker(); //2024-04-17

    // let date = moment();
    // //AUTO LOG-OUT expires in a day(token)
    // if (this.expiryDate != date.format("YYYY-MM-DD")) {
    //   this.$store.commit("AUTH_SUCCESS", []);
    //   this.setExpiryDate("");
    //   // if (this.$router.currentRoute.name != "login") {
    //   //   this.$router.push("/login");
    //   // }
    // }
    ////------------------------
    // Variable to store all http headers
    //========SINGLE SIGN ON================
    // var data = {};
    // // console.log(this.token.length)
    // if (this.token.length == 0) {
    //   axios.defaults.headers.common["x-api-key"] =
    //     "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
    //   // get all headers
    //   axios.get(`${this.api}header`).then((res) => {
    //     data = res.data;
    //       //check IP address
    //       let URL_IP = `${this.api}ipadd`;
    //       axios.get(URL_IP).then((res) => {

    //         let Ip = res.data.split(":");
    //         // console.log(Ip)
    //         //  203.129.123.135
    //         if (Ip[Ip.length - 1] == "10.169.140.132") {
    //           if (data["x_user"]) {
    //             let id = data["x_user"].trim();
    //             //Validate X_USER ID
    //             let url = `${this.api}validate?UserId=${id}`;
    //             axios.get(url).then((res) => {
    //               if (res.data != "Invalid user!") {
    //                 // console.log(res.data);
    //                 this.ADD_INFO(res.data.user_info);
    //                 this.$store.commit("AUTH_SUCCESS", res.data.accessToken);
    //                 this.setExpiryDate(moment().format("YYYY-MM-DD"));
    //                 this.$router.push("/");
    //               } else {
    //                 this.$router.push("/403");
    //               }
    //             });
    //           }
    //         } else {
    //           if (this.$router.currentRoute.name != "login") {
    //             this.$router.push("/login");
    //           }
    //         }
    //       });
    //   });
    // }
    //========END================
  },
  //2024-04-17
  destroyed() {
    this.stopExpiryTimeChecker();
  },
  methods: {
    async gotoRequestMonitoring(){
      window.location.href = `https://requestmonitoring.ihs-w.com/login?email=${this.userInfo.originalMailAddress}&password=${this.currentPass}`
    },
    async saveDefaultPass(){
      console.log('save default password')
      try{
        axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_API_KEY
        if(!this.defaultPass){
          Swal.fire({
            title: "Input password",
            icon: "error",
            showConfirmButton: false,
            timer: 1800,
          });
          return false
        }
        if(this.defaultPassStatus=='Edit'){
          let toSend = {...this.defaultPassList[this.defPassEditIndex]}
          toSend.password = this.defaultPass,
          toSend.UpdatedBy = this.userInfo.name,
          await axios.post(`${this.api}editDefaultPassword`,toSend).then((res)=>{
            console.log(res.data)
            Swal.fire({
              title: "Successfully Updated",
              icon: "success",
              showConfirmButton: false,
              timer: 1800,
            });
            this.defaultPass = ''
            this.addDefPassDialog = false
            this.defaultPassList.splice(this.defPassEditIndex,1,toSend)
            this.defPassEditIndex = -1
          })
        }
        else{
          let url = `${this.api}addDefaultPassword`
          let toSend = {
            password: this.defaultPass,
            RegisterBy: this.userInfo.name,
          }
          await axios.post(url,toSend).then((res)=>{
            console.log(res.data)
            Swal.fire({
              title: "Successfully Added",
              icon: "success",
              showConfirmButton: false,
              timer: 1800,
            });
            this.defaultPass = ''
            this.addDefPassDialog = false
            this.defaultPassList.push(toSend)
          })
        }
      }catch(err){
        console.log(err.message)
      }
    },
    deleteDefPass(index){
      Swal.fire({
          title: `Are you sure you want to delete?`,
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: `Yes`,
          padding: '0px'
      }).then(async(result)=>{
        if(result.value){
          let toDelete = {...this.defaultPassList[index]}
          toDelete.DeletedBy = this.userInfo.name
          toDelete.isDelete = true
          axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_API_KEY
          await axios.post(`${this.api}deleteDefaultPassword`,toDelete).then(res=>{
            console.log(res.data)
            let toDelete = this.defaultPassList[index]
            toDelete.DeletedBy = this.userInfo.name
            toDelete.isDelete = true
            this.defaultPassList.splice(index,1)
            Swal.fire({
              title: "Successfully deleted",
              icon: "success",
              showConfirmButton: false,
              timer: 1800,
            });
          })
        }
      })
    },
    openAddDefPassDialog(status){
      this.defaultPassStatus = status
      this.addDefPassDialog = true
      this.defaultPass = ''
    },
    closeAddDefPassDialog(){
      this.defaultPassStatus = 'Add'
      this.addDefPassDialog = false
      this.defaultPass = ''
      this.defPassEditIndex = -1
    },
    closeDefaultPassDialog(){
      this.defaultPassDialog = false
    },
    async openDefaultPassDialog(){
      try{
        axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_API_KEY
        await axios.get(`${this.api}getAllDefaultPassword`).then(res=>{
          this.defaultPassList = res.data.Items.filter(r=>!r.isDelete)
          this.defaultPassDialog = true
        })
      }
      catch(err){
        console.log(err.message)
      }
    },
    //NOTE: for prompting user to change password when they have default password 2024-03-04
    async checkIfDefaultPass(){
      axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_API_KEY
      let currentUser = await this.getUserDetails(this.userInfo.originalMailAddress)
      //NOTE: for checking if the current login account is deleted 2024-03-04
      if(currentUser.isDelete){
        this.deleteInfo()
        return false
      }
      await axios.get(`${this.api}getAllDefaultPassword`).then((res)=>{
        let checkPass = res.data.Items
        .filter(res=>!res.isDelete)
        .filter(res=>md5(res.password)==currentUser.Password)
        if(checkPass.length!=0){
          this.forceChangePassDialog = true
          console.log('You have a default password!\nPlease change password.')
        }else{
          console.log('Not a default password')
        }
      })
    },
    //NOTE: get user details by email 2024-03-04
    async getUserDetails(email){
      return new Promise(resolve => {
        axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_API_KEY
        axios.get(`${this.api}getUserDetails/${email}`).then(res=>{
          resolve(res.data)
        })
      })
    },
    //NOTE: save new password for users with default password 2024-03-04
    async saveNewPassword(){
      if(this.changePassObj.newPass!=this.changePassObj.reTypePass){
        Swal.fire({
          title: "Password does not match!",
          icon: "error",
          showConfirmButton: false,
          timer: 1800,
        });
        return false
      }
      let newPass = md5(this.changePassObj.newPass)
      let oldPass = await this.getUserDetails(this.userInfo.originalMailAddress)
      if(newPass==oldPass.Password){
        Swal.fire({
          title: "Please enter new password!",
          icon: "error",
          showConfirmButton: false,
          timer: 1800,
        });
        return false
      }
      let userInfo = await this.getUserDetails(this.userInfo.originalMailAddress)
      userInfo.Password = newPass
      userInfo.UpdatedBy = this.userInfo.name;
      userInfo.PasswordChanged = true;
      userInfo.PassChangedBy = this.userInfo.name;
      axios.post(`${this.api}saveUser`,userInfo).then(()=>{
        Swal.fire({
          icon: "success",
          title: "Successfully Updated!",
          showConfirmButton: false,
          timer: 1500,
        }).then(()=>{
          this.forceChangePassDialog = false
          this.deleteInfo()
        });
      })
    },

    getCurrentSystemVersion() {
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
      // console.log(this.api)
      let url = `${this.api}SystemLogs/getAll`;
      axios
        .get(url)
        .then((res) => {
          if (this.appDev == "production") {
            this.currentVersion = res.data.filter((rec) => {
              return rec.isLatestActualVersion == true;
            }); //  [0].version
            let compareVersions = (a, b) => {
              const versionA = a.version.split(".").map(Number);
              const versionB = b.version.split(".").map(Number);

              // Compare each component of the version number
              for (
                let i = 0;
                i < Math.min(versionA.length, versionB.length);
                i++
              ) {
                if (versionA[i] > versionB[i]) return -1;
                if (versionA[i] < versionB[i]) return 1;
              }

              // If all components are equal, longer version numbers come first
              return versionA.length - versionB.length;
            };
            // Sort the array of objects by version number
            this.currentVersion = this.currentVersion.sort(
              compareVersions
            )[0].version;
            console.log(this.currentVersion);
          } else {
            this.currentVersion = res.data.filter((rec) => {
              return rec.isLatestTestVersion == true;
            });
            //  [0].version
            //added 20230
            let compareVersions = (a, b) => {
              const versionA = a.version.split(".").map(Number);
              const versionB = b.version.split(".").map(Number);

              // Compare each component of the version number
              for (
                let i = 0;
                i < Math.min(versionA.length, versionB.length);
                i++
              ) {
                if (versionA[i] > versionB[i]) return -1;
                if (versionA[i] < versionB[i]) return 1;
              }

              // If all components are equal, longer version numbers come first
              return versionA.length - versionB.length;
            };

            // Sort the array of objects by version number
            this.currentVersion = this.currentVersion.sort(
              compareVersions
            )[0].version;
          }
          console.log("System Version: ", this.currentVersion);
        })
        .catch((err) => {
          console.log(err.message);
          alert("Error in fetching system logs version");
        });
    },
    savePassword() {
      // console.log(this.userInfo)
      let url = `${this.api}changeUserPassword`;
      // let url = `http://localhost:5000/changeUserPassword`
      this.toSend.id = this.userInfo.id;

      axios.post(url, this.toSend).then((res) => {
        // console.log(res.data)
        if (res.data == "Old Password is Incorrect.") {
          Swal.fire({
            title: "Old Password is Incorrect.",
            icon: "error",
          });
        } else if (
          res.data == "New Password and Re-type Password is Not Matched"
        ) {
          Swal.fire({
            title: "New Password and Re-type Password is Not Matched.",
            icon: "error",
          });
        } else if (res.data == "Password Changed") {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Password Changed",
            showConfirmButton: false,
            timer: 1000,
          });
          this.toSend = {};
          this.changePassDialog = false;
        }
      });
    },
    userLogs() {
      this.$router.push("/userlogs");
    },
    dashBoard() {
      this.$router.push("/dashboard");
    },

    synonym2() {
      if (this.$router.currentRoute.name != "synonym2") {
        this.$router.push("/synonym2");
      }
    },
    feedback() {
      if (this.$router.currentRoute.name != "feedback") {
        // window.open(
        //   `${this.linkURL}/feedback`,
        //   "_blank"
        // );
        this.$router.push("/feedback");
      }
    },
    systemLogs() {
      this.dialogSystemLogs = true;
    },
    Users() {
      if (this.$router.currentRoute.name != "users") {
        this.$router.push("/users");
      }
    },

    goToCADRequest() {
      // 2022-09-30
      if (this.$router.currentRoute.name != "MasterCADRequestMaterials") {
        this.$router.push("/MasterCADRequestMaterials");
      }
    },
    // 2024-04-17
    checkExpirationtime() {
      let currentTime = new Date().getTime();
      // console.log("Current time:", currentTime);
      if (currentTime >= this.$store.state.expiredTime) {  
        console.log("Session has expired");
        this.deleteInfo();
        this.$store.dispatch('setSessionExpired', true);
        let newExpiredTime = currentTime + (7 * 24 * 60 * 60 * 1000);
        this.$store.commit('setExpiredTime', newExpiredTime);
        // console.log("new expire time", this.$store.state.expiredTime)
        this.$store.dispatch('setLoginTime', currentTime);
      } 
      //   else {
      //   console.log("Session still valid");
      //   console.log("Expired Time:", this.$store.state.expiredTime);
      // }
    },
    startExpiryTimeChecker() {
      this.expiryTimeChecker = setInterval(() => {
        this.checkExpirationtime();
      }, 30 * 60 * 1000);//time check interval(nakaset po sa 30mins)
    },
    stopExpiryTimeChecker() {
      clearInterval(this.expiryTimeChecker);
    },
    logout() {
      let currentTime = new Date().getTime();
      let newExpiredTime = currentTime + (7 * 24 * 60 * 60 * 1000);
      this.$store.commit('setExpiredTime', newExpiredTime);
      this.deleteInfo();
      this.$store.dispatch('resetSessionExpired');
    },
  },
  computed: {
    screenSize() {
      return this.$vuetify.breakpoint.height;
    },

  },
  watch: {
    async $route(to) {
      if(to.path!='/login') await this.checkIfDefaultPass() //NOTE: for prompting user to change password when they have default password 2024-03-05
      if (this.$router.currentRoute.name == "document")
        if (this.$route.query.title) {
          document.title =
            to.meta.title ||
            `@Rulebook ${this.$route.query.title
              .replace(/(<([^>]+)>)/gi, "")
              .replace(/&nbsp;/gi, "")}`;
        } else {
          let urlTitle = `${
            this.api
          }get/title?id=${this.$route.params.id.substring(0, 9)}`;
          axios.defaults.headers.common["x-api-key"] =
            "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
          axios.get(urlTitle).then((res) => {
            document.title =
              to.meta.title ||
              `@Rulebook ${res.data
                .replace(/(<([^>]+)>)/gi, "")
                .replace(/&nbsp;/gi, "")}`;
          });
        }
    },
  },
};
</script>
<style>
.bg-green {
  background-color: #d1f2eb !important;
}
.bg-yellow {
  background-color: #fcf3cf !important;
}
div.ant-tabs-bar {
  margin: 0 !important;
  padding: 0;
}
thead.ant-table-thead > tr > th {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  text-align: center;
  color: #fafafa;
  background-color: #0f3057;
  border-bottom: 1px solid #e8e8e8;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
}

.smallerH {
  font-size: 15px;
}

.fr-view {
  font-family: "MS PGothic", Osaka, Arial, sans-serif;
  font-size: 18px;
}

.fr-view table.noborder tbody td {
  border: none;
}

.fr-view table.blackborder tbody td {
  border-color: black;
}
.fr-view td.tableCellStyles1 {
  color: red;
}

.fr-view span.rotateVerticalLeftRight {
  writing-mode: vertical-lr;
}
.fr-view .class1 tbody tr:nth-child(2n) {
  background: #d3d6db;
}
.fr-view .class2 thead tr th,
.class2 tbody tr td {
  border-style: dashed;
}

a[href="https://www.froala.com/wysiwyg-editor?k=u"] {
  position: absolute;
  top: -99999999px;
}

.dialog {
  position: absolute;
  bottom: 0;
  right: 0;
}

.scroll {
  overflow-y: auto;
}

#myTable {
  min-height: calc(100vh - 200px);
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  overflow-x: auto;
}
#app {
  text-align: left;
}
@media only print {
  body {
    margin: 0mm !important;
    padding: 0mm !important;
  }
  .hidePrint {
    display: none !important;
  }
}
</style>
